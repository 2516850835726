.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

body,html{
  height: 100%;
}


.overflow-auto {
  overflow: auto;
}

.wrapperStyle {
  max-height: 0;
}

.card-main {
  overflow: hidden;
}

.header {
  border-bottom: 1px solid #e1e4e8;
}

.header > h2 {
  font-weight: normal;
}

.formBold {
  font-weight: 600;
}

.ml1 {
  margin-left: 10px;
}